.popular-and-banner-edit-page {
  td.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left.ant-table-cell-fix-left-last, td.ant-table-cell.ant-table-selection-column.ant-table-cell-fix-left {
    text-align: center!important;
  };
}

.popular-and-banner-page {
  .ant-descriptions-item {
    padding-right: 10px
  }
}