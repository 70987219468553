.search-text, .search-selector {
  width: 200px;
}

.search-text {
  float:left;
}

.search-selector {
  float:right;
}

.core-search {
  position: relative;
  z-index: 4;
}
.search-selector-role {
  width: 100px;
  float: right;
  margin-left: 10px;
}

.search-selector-view-rank {
  width: 150px;
  float: right;
  margin-left: 10px;
}