.ant-upload.ant-upload-select-picture-card {
  margin-right: 0px;
  margin-bottom: 0px;
}


.ant-upload-list-picture-card-container {
  width: 90px;
  height: 90px;
  margin: 0px 10px;
}

.ant-upload-list.ant-upload-list-picture-card {
  display: flex;
}

.ant-upload-picture-card-wrapper {
  width: 200%;
}

button.ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn {
  display: none;
}