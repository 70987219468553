


.voting-edit-page {
  .option {
    display: flex;
    button {
      margin-right: 10px;
    }
  }
  .ant-descriptions-item-content {
    justify-content: flex-end;
    margin-right: 20px;
    .upload-image__form {
      display: flex;
      button {
        margin-right: 10px;
        align-self: end;
      }
      .ant-row.ant-form-item, .ant-upload {
        margin-bottom: 0px;
        margin-right: 0px;
      }
    }
  }
  .vote-method {
    .ant-upload-picture-card-wrapper {
      width: 100%;
    }
  }
}
