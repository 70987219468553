@import "~antd/dist/antd.css";
// Abstract files
@import "./abstracts/mixins";
@import "./abstracts/variables";

// Components
@import "./components/avatar";
@import "./components/modal";
@import "./components/search";
@import "./components/button";
@import "./components/upload-form";
@import "./components/table";

// Core files
@import "./core/common";
@import "./core/reset";
@import "./core/typography";

// Layout
@import "./layout/footer";
@import "./layout/header";
@import "./layout/navigation";

// Pages
@import "./pages/login";
@import "./pages/members";
@import "./pages/member-detail";
@import "./pages/content-detail";
@import "./pages/episode-detail";
@import "./pages/episode-add";
@import "./pages/content";
@import "./pages/upload-video";
@import "./pages/season-add";
@import "./pages/event";
@import "./pages/funding";
@import "./pages/voting-detail";
@import "./pages/popular-banner";
