.member-page {
  .ant-table-body {
    overflow: auto!important;
  }
}

  #components-layout-demo-top-side-2 .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: rgba(255, 255, 255, 0.3);
  }
  
  .ant-row-rtl #components-layout-demo-top-side-2 .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-page-header {
    border: 1px solid rgb(235, 237, 240);
    margin-bottom: 20px;
  }