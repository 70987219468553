:root {
  --black-primary: #202325;
  --gray-primary: #5D5A64;
  --gray-secondary: #848484;
  --gray-tertiary: #cecece;
  --gray-quaternary: #F8F8F8;
  --blue-primary: #3E79F7;
  --blue-quaternary: #E3EBF6;
  --orange-primary: #FF6B00;
  --orange-secondary: #ED7B09;
  --danger-primary: #FF4D4F;
  --danger-tertiary: #FFDFCF;

  --box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%), 0 5px 12px 4px rgb(0 0 0 / 9%);
  --box-shadow-bottom: 0 1px 2px #0000001a, 0 -1px #0000001a inset, 0 2px 1px -1px #ffffff80 inset;
  --border-solid-gray-primary: 1px solid var(--gray-primary);
  --border-solid-gray-tertiary: 1px solid var(--gray-tertiary);
  --border-dashed-gray: 1px dashed var(--gray-primary);
}