.flex-center-horizontal {
  @include flex-center-horizontal;
}

.flex-center-vertical {
  @include flex-center-vertical;
}

.flex-center {
  @include flex-center;
}

.text-center {
  text-align: center;
}

.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-error > .ant-form-item-control {
  margin-bottom: 24px;
  .ant-row.ant-form-item {
    margin-bottom: 0px;
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 16px;
}

.row-dragging .drag-visible {
  visibility: visible;
}