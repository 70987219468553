.upload-form {
  .upload-image__text {
    span.ant-descriptions-item-content {
      display: block;

      span.ant-typography {
        display: block;
        color: rgb(142 142 142 / 85%);
      }
    }
  }
  .ant-descriptions-item-content {
    justify-content: flex-end;
    width: 100%;
    .upload-image__form {
      display: flex;
        button {
          align-self: end;
        }
      .ant-upload.ant-upload-select.ant-upload-select-picture-card {
        margin-right: 0px;
      }
      .ant-row.ant-form-item,
      .ant-upload {
        margin-bottom: 0px;
        margin-right: 10px;
      }
    }
  }
}
