.member-detail-page {
  form.ant-form.ant-form-horizontal {
    width: 800px !important;
    .form-avatar {
      .ant-form-item-control-input-content {
        display: inline-grid;
      }
    }
    .form-data-member {
      width: 500px !important;

      span.ant-descriptions-item-content {
        display: block !important;
      }
    }
  }
  .information-detail {
    .ant-descriptions-item-content {
      display: block;
    }
  }

  .header-title {
    padding: 16px 0px;
  }
}
