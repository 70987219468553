.content-management-page {
  th.ant-table-cell, td.ant-table-cell {
      text-align: center!important;
  }

  td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
    text-align: left!important;
  }

}

