.navigation {
  flex-direction: row;

  .side-bar__menu {
    position: relative;
    box-shadow: 2px 0 8px 0 rgb(29 35 41 / 5%);

    button.ant-switch {
      margin-left: 5px;
      position: absolute;
      bottom: 0;
    }
    ul {
      width: 100%;
    }
  }
  .admin-container {
    padding: 20px;
  }
  .ant-layout-sider {
    background: #fff;

    .ant-layout-sider-trigger {
      background: #fff;

      span.anticon.anticon-left, span.anticon.anticon-right {
        color: black;
      }
    }
  }
  .ant-layout {
    height: calc(100vh - 50px);
    overflow-y: auto;
  }
}