.header {
    background: #002140!important;
    position: relative;
    box-shadow: 0 4px 2px -2px #f3f3f3;
    z-index: 9;
    position: sticky;
    top: 0;
    height: 50px!important;

    .login-logo {
      padding-bottom: 20px;
      width: 125px;
      cursor: pointer;
    }

    .resource {
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  
    .site_name {
      span {
        font-size: 29px;
        font-weight: 600;
        letter-spacing: -2px;
        color: #000;
        cursor: pointer;
      }
  
      strong {
        color: #ff0000;
        font-weight: 600;
      }
    }
    span.anticon.anticon-global.ant-dropdown-trigger.resource {
      svg {
        color: #fff;
      }
    }
    .menu {
      margin-bottom: 0;
  
      .header-menu {
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: right;
        align-items: center;
        height: 100%;
  
        li {
          padding: 0 10px;
          color: #666;
          border-right: 1px solid #666;
          font-size: 12px;
          cursor: pointer;
          
          .id {
            color: #333;
            font-weight: 600;
            padding: 0 9px;
            height: 19px;
            line-height: 15px;
            box-sizing: border-box;
            border: 1px solid #d4d4d5;
            border-radius: 100px;
            background: #fff;
            margin-left: 5px;
            margin-top: 1px;
          }
        }
      }
  
      .ant-list-items {
        display: flex;
        justify-content: right;
  
        .ant-list-item {
          padding: 10px;
          font-size: 12px;
          cursor: pointer;
          border-right: 1px solid #666;
          padding: 0 15px;
  
          span {
            color: #666;
          }
        }
      }
    }
  }
  
  .nav-menu {
    background: #353c43;
    margin: 0 auto;
    padding: 0 5%;
  
    .ant-list-items {
      display: flex;
      justify-content: center;
      cursor: pointer;
  
      .ant-list-item {
        a {
          padding: 10px 15px;
        }

        span {
          font-size: 15px;
          letter-spacing: -1px;
          font-weight: 600;
          color: #fff;
        }
  
        border-bottom: none;
        border-right: 1px solid #fff;
      }
  
      .ant-list-item:first-child {
        border-left: 1px solid #fff;
      }
    }
  }
  
  .sub-menu {
    padding: 9px 15px 0;
  }
  
  .sub-menu-item {
    padding: 3px 10px 6px 0;
    cursor: pointer;
    color: #666;
    border-bottom: 1px solid #d9dee3;
    font-size: 12px;
  
    &:hover {
      background: inherit;
      color: #000;
    }
  }
  
  .sub-menu-item-last {
    border-bottom: none !important;
    background: #f4f5f7;
    margin: 0 -15px;
    padding-right: 15px;
    padding-left: 15px;
  
    color: #000;
    font-size: 10px;
    font-weight: 500;
  
    &:hover {
      background: #f4f5f7;
    }
  
    a {
      display: flex;
      justify-content: space-between;
    }
  
    button {
      background: #fff;
      border: 1px solid #ccc;
      box-sizing: border-box;
      padding: 0 6px 0 5px;
      overflow: hidden;
      white-space: nowrap;
      text-decoration: none !important;
      color: #999;
      letter-spacing: -1px;
      font-size: 11px;
      border-radius: 2px;
      height: 20px;
    }
  }
  
  .home-header {
    background: white;
    padding: 0 50px;
  
    &-top {
      padding: 16px 0px 0px;
      position: static;
      height: 80px;
      top: 0px;
  
      a {
        padding: 0 16px;
        font-size: 12px;
        line-height: 18px;
        font-weight: 500;
        color: #111111;
  
        &:first-child {
          padding-left: 0px;
        }
  
        &:nth-child(3) {
          padding-right: 0px;
        }
  
        &:nth-child(2) {
          border-right: 1px solid #e4e8ec;
          border-left: 1px solid #e4e8ec;
        }
      }
  
      .ant-col {
        &:nth-child(2) {
          text-align: center;
        }
  
        &:nth-child(3) {
          text-align: right;
        }
      }
    }
  
    &-menu {
      align-items: center;
      margin: 10px 0;
  
      .ant-image-img {
        margin-left: 16px;
        width: auto;
      }
  
      .ant-col {
        &:nth-child(2) {
          justify-content: flex-end;
          align-items: center;
          display: flex;
        }
      }
  
      .ant-input-search {
        border-radius: 20px;
      }
  
      .left {
        display: flex;
        align-items: center;
  
        .anticon-menu {
          cursor: pointer;
          font-size: 16px;
        }
  
        .ant-list-item {
          display: contents;
          font-weight: 500;
          font-size: 16px;
          line-height: 22px;
          cursor: pointer;
  
          span {
            padding: 30px;
            text-transform: uppercase;
          }
  
          &:first-child {
            span {
              padding-left: 10px;
            }
          }
        }
      }
      
      .ant-image-img {
        cursor: pointer;
      }
    }
  }
  .avatar-dropdown__menu {
    .anticon {
      margin-right: 8px;
    }
    :global(.ant-dropdown-menu-item) {
      min-width: 160px;
    }
  }
  .right {
    display: flex;
    float: right;
    height: 48px;
    margin-left: auto;
    overflow: hidden;

    .name {
      color: #fff;
      margin-left: 5px;
    }
  }
  .languages {
    margin-top: 18px;
    span.ant-dropdown-menu-title-content {
      display: flex;
      .logo-languages {
        width: 20px;
        margin-right: 5px;
      }
    }
  }

  .header-dropdown__action {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 12px;
    cursor: pointer;
    transition: all 0.3s;
    > span {
      vertical-align: middle;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    &:global(.opened) {
      background: rgba(0, 0, 0, 0.025); 
    }
  }
  .account {
    .avatar {
      margin-right: 8px;
      color: #1890ff;
      vertical-align: top;
      background: rgba(255, 255, 255, 0.85);
    }
  }



  .header-page-top {
    background: #fff;
    height: auto;

    .page-top {
      padding: 20px 0px;
      .ant-breadcrumb {
        margin-bottom: 10px;
      }
    }
  }