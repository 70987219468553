


.episode-add-page {
  .vote {
    .vote-method {
      width: 35%;
      .ant-upload-list.ant-upload-list-picture-card {
        justify-content:flex-end;
      }
      .ant-upload-picture-card-wrapper {
        width: 100%!important;
      }
    }
  }
}
