.upload-video-page {
  .image-upload-video-step {
    margin-top: 20px;

    .upload-image__text {
      span.ant-descriptions-item-content {
        display: block;

        .upload-image__form {
          margin-top: 0px;
        }
        span.ant-typography {
          display: block;
          color: rgb(142 142 142 / 85%);
        }
      }
    }
    .ant-descriptions-item-content {
      justify-content: flex-end;
      margin-right: 20px;
      .upload-image__form {
        display: flex;
        button {
          margin-right: 10px;
          align-self: end;
        }
        .ant-row.ant-form-item,
        .ant-upload {
          margin-bottom: 0px;
          margin-right: 0px;
        }
      }
    }
  }
  .episode-upload-video-step {
    margin: 20px 0px;
    .vote {
      .vote-method {
        width: 35%;
        .ant-upload-list.ant-upload-list-picture-card {
          justify-content: flex-end;
        }
        .ant-upload-picture-card-wrapper {
          width: 100% !important;
        }
      }
    }
    .btn-add {
      margin-bottom: 20px;
    }
    .option-upload-video {
      width: 200px;
      float: right;
    }
  }
  .basic-information-content-tabs {
    margin: 20px 0px;

    .btn-add {
      margin-bottom: 20px;
    }
    .site-page-header {
      padding: 10px 20px;
      .ant-page-header-heading-left {
        margin: 0px;
        span.ant-page-header-heading-title {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }
}

.ant-upload.ant-upload-select-picture-card {
  width: 90px;
  height: 90px;
  span.ant-upload {
    width: 100%;
  }
}

.icon-remove {
  float: right;
  margin-bottom: 10px;
}

.vote {
  .vote-method {
    width: 35%;
    .ant-upload-list.ant-upload-list-picture-card {
      justify-content: flex-end;
    }
  }
  .voting-input {
    width: 60%;
    margin-left: 20px;
  }
}

.story-wrapper {
  border: 1px solid #d9d9d9;
  padding: 5px;
}
